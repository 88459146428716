<template>
  <div>
    <!-- Content Header (Page header) -->

    <!-- Main content -->
    <section class="content" style="margin-top: 10px">
      <!-- Default box -->
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-white" style="padding-top: 10px">Categories</h3>
          <div class="card-tools">
            <button class="btn btn-secondary btn-sm" @click="refresh"><i
                class="fas fa-sync-alt"></i> Refresh
            </button>
          </div>
        </div>
        <div class="card-header">
          <div class="card-tools">
            <button class="btn btn-success" @click="modalCreate" data-toggle="modal" data-target="#addInfo"><i
                class="fas fa-user-plus"></i> Add New
            </button>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="fullPage"
                   :z-index="zIndex"
                   loader="bars">
          </loading>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="bg-teal">
              <tr>
                <th style="width: 20px">
                  Name
                </th>
                <th style="width: 15px">
                  Parent
                </th>
                <th style="width: 15px">
                  Min Price
                </th>
                <th style="width: 15px">
                  Min Amount
                </th>
                <th style="width: 10px" class="text-center">
                  Active
                </th>
                <th style="width: 10px" class="text-center">
                  Action
                </th>
              </tr>
              </thead>
              <tbody v-if="parseInt(categories.total) === 0">
              <td colspan="6">
                <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
              </td>
              </tbody>
              <tbody v-else>
              <tr v-for="category in categories.data" :key="category.id">
                <td>
                  {{ category.name }}
                </td>
                <td>
                  <span v-if="category.parent_id">{{ category.parent.name }}</span>
                  <span v-else class="badge badge-success">Yes</span>
                </td>
                <td>
                  {{ currency }} {{ category.price }}
                </td>
                <td>
                  {{ category.min_amount }}
                </td>
                <td class="project-state">
                  <span class="badge badge-danger" v-if="parseInt(category.active) === 0">No</span>
                  <span class="badge badge-success" v-if="parseInt(category.active) === 1">Yes</span>
                </td>
                <td class="project-actions text-right">
                  <button class="btn btn-danger" @click.prevent="setInfo(category)">View Details</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer clearfix">
          <pagination class="pagination pagination-sm m-0 float-right" :data="categories"
                      @pagination-change-page="getCategories"></pagination>
        </div>
      </div>
      <!-- /.card -->
      <!-- View Modal -->
      <div class="modal fade" ref="viewInfo" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">Category Info</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-info">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <a>Name <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.name"><strong>{{ info.name }}</strong></span>
              </div>
              <div class="form-group">
                <a>Slug <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.slug"><strong>{{ info.slug }}</strong></span>
              </div>
              <div class="form-group">
                <a>Minimum Price <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.price !== null"><strong>{{ currency }} {{ info.price }}</strong></span>
              </div>
              <div class="form-group">
                <a>Minimum Amount <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.min_amount !== null"><strong>{{ info.min_amount }}</strong></span>
              </div>
              <div class="form-group">
                <a>Active <strong>:</strong> </a>
                <span class="badge badge-danger float-right" v-if="parseInt(info.active) === 0">No</span>
                <span class="badge badge-success float-right" v-if="parseInt(info.active) === 1">Yes</span>
              </div>
              <div class="form-group">
                <a>Parent <strong>:</strong> </a>
                <span class="float-right" v-if="!(info.parent_id === null)">{{ info.parent.name }}</span>
                <span v-else class="badge badge-success float-right">Yes</span>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="modalUpdate" data-toggle="modal"
                      data-target="#addInfo">Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit/Add Modal -->
      <div class="modal fade" id="addInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="addNew" class="modal-title" id="createModalLabel">Create Category</h5>
              <h5 v-if="!addNew" class="modal-title" id="updateModalLabel">Update Category</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="form-horizontal" enctype="multipart/form-data">
              <div class="modal-body">
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputName"
                           placeholder="Category Name" v-model="info.name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputSlug"
                         class="col-sm-2 col-form-label">Slug</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputSlug"
                           placeholder="Category Slug" v-model="info.slug">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPrice"
                         class="col-sm-2 col-form-label">Minimum Price</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="inputPrice"
                           placeholder="Minimum Price" v-model.number="info.price">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputAmount"
                         class="col-sm-2 col-form-label">Minimum Amount</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="inputAmount"
                           placeholder="Minimum Amount" v-model.number="info.min_amount">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputCurrency" class="col-sm-2 col-form-label">Parent Id</label>
                  <div class="col-sm-10">
                    <select id="inputCurrency" class="custom-select" v-model.number="info.parent_id">
                      <option value="0" selected>None</option>
                      <option v-for="parent in parents" :key="parent.id" :value="parent.id">{{ parent.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputActive" class="col-sm-2 col-form-label">Active</label>
                  <div class="col-sm-10">
                    <select id="inputActive" class="custom-select" v-model.number="info.active">
                      <option value="1" selected>Yes</option>
                      <option value="0" selected>No</option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button v-if="addNew" type="button" class="btn btn-success" @click.prevent="categoryCreate">Add
                  Category
                </button>
                <button v-if="!addNew" type="button" class="btn btn-warning" @click.prevent="categoryUpdate">Update
                  Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.refresh();
  },
  computed: {
    currency() {
      return this.$currency;
    }
  },
  data() {
    return {
      categories: {
        total: 0,
      },
      parents: {},
      info: {
        id: null,
        parent_id: null,
        name: null,
        slug: null,
        price: null,
        min_amount: 0,
        active: null,
        parent: {
          id: null,
          name: null,
        },
      },
      errors: {},
      addNew: false,
      loading: false,
      isLoading: false,
      fullPage: false,
      zIndex: 10,
    }
  },
  methods: {
    refresh() {
      this.getCategories();
      this.getParents();
    },
    getCategories(page = 1) {
      this.isLoading = true;
      axios.get('admin/categories?page=' + page)
          .then(({data}) => {
            this.categories = data;
            this.isLoading = false
          })
    },
    getParents() {
      this.isLoading = true;
      axios.get('admin/category-parents')
          .then(({data}) => {
            this.parents = data;
            this.isLoading = false
          })
    },
    setInfo(category) {
      this.info = {
        id: null,
        parent_id: null,
        name: null,
        slug: null,
        price: null,
        min_amount: 0,
        active: null,
        parent: {id: null, name: null}
      };
      this.info = category;
      $('#viewInfo').modal('show')
    },
    categoryCreate() {
      axios.post('admin/category-add', this.info)
          .then(() => {
            this.refresh();
            this.$toast('success', 'Category Created');
            document.getElementById('close').click();
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    categoryUpdate() {
      axios.patch('admin/category-update', this.info)
          .then(() => {
            this.refresh();
            this.$toast('success', 'Category Updated');
            this.toggleModal();
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    modalUpdate() {
      this.addNew = false;
    },
    modalCreate() {
      this.addNew = true;
      this.info = {
        id: null,
        parent_id: 0,
        name: null,
        slug: null,
        price: null,
        min_amount: null,
        active: 1,
        parent: {id: null, name: null}
      };
    },
    toggleModal() {
      document.getElementById('close').click();
      document.getElementById('close-info').click();
    },

  },

}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

#instructions {
  color: floralwhite;
  background-image: linear-gradient(109.6deg, rgba(121, 203, 202, 1) 11.2%, rgba(119, 161, 211, 1) 91.1%);
}
</style>
