<template>
  <section class="content" style="margin-top: 10px">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" style="padding-top: 15px">
            <h3 class="card-title text-white">User's List</h3>
            <div class="card-tools">
              <button class="btn btn-secondary btn-sm" @click="getUsers"><i
                  class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card-header" style="padding-top: 10px">
                <div class="align-self-center text-white mr-1">
                  <label>Sort By : </label>
                </div>
                <div class="row" style="justify-content: center;">
                  <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                    <select id="status" class="custom-select" v-model="filter.status" @change="getUsers">
                      <option value="0" selected>All</option>
                      <option value="1">Active</option>
                      <option value="2">Blocked</option>
                      <option value="3">Not Verified</option>
                    </select>
                  </div>
                  <div style="width: 55%; margin-bottom: 10px;">
                    <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getUsers">
                      <option value="desc" selected>Latest</option>
                      <option value="asc">Oldest</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card-header" style="padding-top: 10px">
                <div class="align-self-center text-white mr-1">
                  <label>Search By : </label>
                </div>
                <div class="row" style="justify-content: center;">
                  <div style="width: 65%; margin-right: 5px; margin-bottom: 10px;">
                    <input type="text" class="form-control" placeholder="Name, Username or Email" v-model="filter.search">
                  </div>
                  <div style="width: 25%; margin-bottom: 10px;">
                    <button class="btn btn-dark float-md-right ml-3" @click="reset">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="bg-teal">
                <tr>
                  <th style="width: 15%">
                    Name
                  </th>
                  <th style="width: 10%">
                    Username
                  </th>
                  <th style="width: 15%">
                    Email
                  </th>
                  <th style="width: 10%">
                    Balance
                  </th>
                  <th style="width: 15%">
                    Joined
                  </th>
                  <th style="width: 10%">
                    Blocked
                  </th>
                  <th style="width: 25%;text-align: center">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody v-if="parseInt(users.total) === 0">
                <td colspan="7">
                  <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                </td>
                </tbody>
                <tbody v-else>
                <tr v-for="user in users.data">
                  <td>
                    {{ user.name }}
                  </td>
                  <td>
                    {{ user.username }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>
                    {{currency}} {{ user.balance }}
                  </td>
                  <td v-if="moment.utc(user.created_at).isSameOrAfter(moment.utc().subtract(7,'days').startOf('day'))">
                    {{ user.created_at | fromNow }}
                  </td>
                  <td v-else>
                    {{user.created_at | dateFormat}}
                  <td class="text-center">
                    <span class="badge badge-success" v-if="parseInt(user.banned) === 0">No</span>
                    <span class="badge badge-danger" v-if="parseInt(user.banned) === 1">Yes</span>
                  </td>
                  <td class="project-actions text-center" style="width: 25%">
                    <button class="btn btn-sm bg-pink" @click="setInfo(user)" data-toggle="modal" data-target="#viewInfo"><i class="fas fa-eye"> View</i></button>
                    /
                    <router-link :to="{name: 'user-details', params:{username:user.username}}" class="btn btn-sm bg-yellow"><i class="fas fa-info-circle"> Details</i></router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <pagination class="pagination pagination-sm m-0 float-right" :data="users"
                        @pagination-change-page="getUsers"></pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{info.name}}'s Info</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close"><i class="fas fa-times-circle"></i></button>
          </div>
          <div class="modal-body">
            <!-- Profile Box -->
            <div class="card card-warning card-outline" id="profileBox">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img v-if="info.image" class="profile-user-img img-fluid img-circle" :src="url+info.image" :alt="info.name">
                  <img v-else class="profile-user-img img-fluid img-circle bg-white" :src="url+'/user.png'" :alt="info.name">
                </div>
                <h3 class="profile-username text-white text-center">{{info.name}}</h3>
                <p class="text-muted text-white text-center">@{{info.username}}</p>
              </div>
            </div>
            <!-- /Profile Box -->
            <div class="form-group">
              <a>Email <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.email"><strong>{{ info.email }}</strong></span>
            </div>
            <div class="form-group">
              <a>Balance <strong>:</strong> </a>
              <span class="float-right" v-if="info.balance"><strong>{{currency}} {{ info.balance }}</strong></span>
            </div>
            <div class="form-group">
              <a>Country <strong>:</strong> </a>
              <span class="float-right" v-if="info.country && info.country.name"><strong>{{ info.country.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Blocked <strong>:</strong> </a>
              <span class="badge badge-success float-right" v-if="parseInt(info.banned) === 0">No</span>
              <span class="badge badge-danger float-right" v-if="parseInt(info.banned) === 1">Yes</span>
            </div>
            <div class="form-group">
              <a>Date <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.created_at"><strong>{{ info.created_at | timeDate }}<small> ({{info.created_at | fromNow}})</small></strong></span>
            </div>
            <div class="form-group">
              <a>Bio <strong>:</strong> </a>
              <p class="font-italic ml-2" v-if="info.bio">{{info.bio}}</p>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-warning" data-toggle="modal" data-target="#message" @click="toggleModal" ><i class="fas fa-envelope"> Message</i></button>
            <button class="btn btn-success" @click="viewDetails(info.username)"><i class="fas fa-info-circle"> Details</i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="message" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Send Message <small>({{this.info.name}})</small></h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-message"><i class="fas fa-times-circle"></i></button>
          </div>
          <div class="modal-body">
            <!-- Chat -->
            <div class="direct-chat-messages">

              <div v-if="parseInt(messages.count) !== 0" class="direct-chat-msg right" v-for="message in messages.items" :key="message.id">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>{{ message.created_at | timeDate }}</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                  <div v-html="message.message"></div>
                </div>
              </div>
              <div v-if="parseInt(messages.count) === 0" class="direct-chat-msg right">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>N/A</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                 No Previous Messages Available !!
                </div>
              </div>


            </div>
            <!--/.direct-chat-messages-->

          </div>
          <div class="modal-footer">
            <div class="input-group">
              <textarea type="text" name="message" placeholder="Type Message ..." class="form-control" v-model="messages.message_text" />
              <span class="input-group-append ml-1">
                      <button class="btn btn-warning rounded" @click.prevent="sendMessage">Send</button>
                    </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getUsers();
  },
  data() {
    return {
      moment,
      url: this.$fileURL,
      users: {
        total:0,
      },
      messages: {
        message_text: null,
        count:0,
        items:{}
      },
      info: {
        id: null,
        name: null,
        username: null,
        email: null,
        bio: null,
        balance: null,
        image: null,
        banned: null,
        created_at: null,
        country:{
          name: null,
        }
      },
      filter:{
        status: '0',
        sortBy: 'desc',
        search: '',
      },
      isLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  computed: {
    currency() {return this.$currency},
    searchTerm() {
      return this.filter.search;
    }
  },
  watch: {
    searchTerm() {
      this.getUsers();
    }
  },
  methods: {
    getUsers(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== ''){
        status = parseInt(status)
        if (status === 0){
          status = ''
        }
      }
      let search = this.filter.search
      let sortBy = this.filter.sortBy
      axios.get('admin/users',{params:{status: status,search: search,sortBy: sortBy}})
          .then(({data}) => {this.users = data;this.isLoading = false})
    },
    reset() {
      this.filter = {status:'0',sortBy:'desc', search:''};
      this.getUsers();
    },
    setInfo(user) {
      this.info = user;
     this.getMessages();
    },
    getMessages(){
      axios.get('admin/last-messages',{params:{username:this.info.username}})
          .then(({data}) => {this.messages.items = data.messages;this.messages.count = data.total})
    },
    viewDetails(username){
      document.getElementById('close').click();
      this.$router.push({name: 'user-details', params:{username:username}})
    },
    sendMessage(){
      axios.post('admin/send-message',{username:this.info.username,message:this.messages.message_text})
          .then(({data})=> {
            this.messages.message_text = null;
            this.$toast('success', ''+data.message+'')
            this.getMessages();
          })
    },
    updateStatus(id){
      axios.post('admin/offer-status-update',{id:id,status:this.status})
          .then(({data})=> {
            this.$toast('success', ''+data.message+'')
            this.getUsers();
          })
      this.toggleModal();
    },
    toggleModal(){
      document.getElementById('close').click();
    },

  },
}
</script>

<style scoped>
@media screen and (min-width: 1200px){
  .table {
    table-layout: fixed;
    word-wrap: break-word;
  }
}
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
#profileBox{
  background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}
</style>
