<template>
  <div>
    <!-- Main content -->
    <section class="content" style="margin-top: 10px">

      <!-- Default box -->
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-white" style="padding-top: 15px">Pages</h3>
          <div class="card-tools">
            <button class="btn btn-secondary btn-sm" @click="getAllPages(1)"><i
                class="fas fa-sync-alt"></i> Refresh
            </button>
          </div>
        </div>
        <div class="card-header">
          <div class="card-tools">
            <button class="btn btn-success" @click="modalCreate" data-toggle="modal" data-target="#addInfo"><i
                class="fas fa-user-plus"></i> Add New
            </button>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="fullPage"
                   :z-index="zIndex"
                   loader="bars">
          </loading>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="bg-teal">
              <tr>
                <th style="width: 30px">
                  Title
                </th>
                <th style="width: 15px">
                  Slug
                </th>
                <th style="width: 10px" class="text-center">
                  Active
                </th>
                <th style="width: 10px" class="text-center">
                  Action
                </th>
              </tr>
              </thead>
              <tbody v-if="parseInt(pages.total) === 0">
              <td colspan="6">
                <div class="card-body bg-gray-light text-center text-xl">No Pages Available !!</div>
              </td>
              </tbody>
              <tbody v-else>
              <tr v-for="item in pages.data" :key="item.id">
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.slug }}
                </td>
                <td class="project-state">
                  <span class="badge badge-danger" v-if="parseInt(item.status) === 0">No</span>
                  <span class="badge badge-success" v-if="parseInt(item.status) === 1">Yes</span>
                </td>
                <td class="project-actions text-right">
                  <button class="btn btn-danger" @click="setInfo(item)" data-toggle="modal" data-target="#viewInfo">
                    View Details
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer clearfix">
          <pagination class="pagination pagination-sm m-0 float-right" :data="pages"
                      @pagination-change-page="getAllPages"></pagination>
        </div>
      </div>
      <!-- /.card -->
      <!-- View Modal -->
      <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">Page Info</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-info">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <a>Title <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.title"><strong>{{ info.title }}</strong></span>
              </div>
              <div class="form-group">
                <a>Slug <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.slug"><strong>{{ info.slug }}</strong></span>
              </div>
              <div class="form-group">
                <a>Active <strong>:</strong> </a>
                <span class="badge badge-danger float-right" v-if="parseInt(info.status) === 0">No</span>
                <span class="badge badge-success float-right" v-if="parseInt(info.status) === 1">Yes</span>
              </div>
              <div class="form-group">
                <label>Short Description</label>
                <p style="white-space: pre">{{ info.short_description }}</p>
              </div>
              <div class="form-group">
                <a>Content <strong>:</strong> </a>
                <div id="instructions" class="card-body card2" v-if="info.content" v-html="info.content">
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="modalUpdate" data-toggle="modal"
                      data-target="#addInfo">Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit/Add Modal -->
      <div class="modal fade" id="addInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="addNew" class="modal-title" id="createModalLabel">Create Page</h5>
              <h5 v-if="!addNew" class="modal-title" id="updateModalLabel">Update Page</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <form class="form-horizontal" enctype="multipart/form-data">
              <div class="modal-body">
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Title</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputName"
                           placeholder="Page Name" v-model="info.title">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputSlug"
                         class="col-sm-2 col-form-label">Slug</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputSlug"
                           placeholder="Page Slug" v-model="info.slug">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputActive" class="col-sm-2 col-form-label">Active</label>
                  <div class="col-sm-10">
                    <select id="inputActive" class="custom-select" v-model.number="info.status">
                      <option value="1" selected>Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Short Description :</label>
                  <textarea type="text" class="form-control" placeholder="Short Description"
                            v-model="info.short_description"></textarea>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Content :</label>
                  <div>
                    <vue-editor id="inputDescription" v-model="info.content" :editorToolbar="customToolbar"/>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button v-if="addNew" type="button" class="btn btn-success" @click.prevent="PageCreate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Add Page
                </button>
                <button v-if="!addNew" type="button" class="btn btn-warning" @click.prevent="pageUpdate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import axios from "axios";
import svgLoading from "@/views/shared/loading-svg";
import {VueEditor} from "vue2-editor";

export default {
  components: {VueEditor, svgLoading},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getAllPages();
  },
  computed: {
    currency() {
      return this.$currency;
    }
  },
  data() {
    return {
      pages: {
        total: 0,
      },
      info: {
        id: null,
        title: null,
        slug: null,
        short_description: null,
        status: 1,
        content: null,
      },
      errors: {},
      addNew: false,
      loading: false,
      customToolbar: [
        [{
          header: [false, 1, 2, 3]
        }], ["bold", "italic", "underline", "clean"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}], ["blockquote", "video", "link"],
        [{list: "ordered"}, {list: "bullet"}], [{indent: "-1"}, {indent: "+1"}], [{color: []}, {background: []}]
      ],
      isLoading: false,
      fullPage: false,
      zIndex: 10,
    }
  },
  methods: {
    getAllPages(page = 1) {
      this.isLoading = true;
      axios.get('admin/pages?page=' + page)
          .then(({data}) => {
            this.pages = data;
            this.isLoading = false
          })
    },
    setInfo(item) {
      this.info = item
    },
    PageCreate() {
      this.loading = true;
      axios.post('admin/page-add', this.info)
          .then(() => {
            this.getAllPages();
            this.loading = false;
            this.$toast('success', 'Page Created Successfully');
            document.getElementById('close').click();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    pageUpdate() {
      this.loading = true;
      axios.patch('admin/page-update', this.info)
          .then(() => {
            this.loading = false;
            this.getAllPages();
            this.$toast('success', 'Page Updated');
            this.toggleModal();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    modalUpdate() {
      this.addNew = false;
    },
    modalCreate() {
      this.addNew = true;
      this.info = {id: null, title: null, slug: null, short_description: null, status: 1, content: null};
    },
    toggleModal() {
      document.getElementById('close').click();
      document.getElementById('close-info').click();
    },

  },

}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

#instructions {
  color: floralwhite;
  background-image: linear-gradient(109.6deg, rgba(121, 203, 202, 1) 11.2%, rgba(119, 161, 211, 1) 91.1%);
}
</style>
