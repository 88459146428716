<template>
  <!-- Main content -->
  <section class="content" style="padding-top: 10px">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg-dark">
            <div class="card-title text-white">User Details</div>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Profile Box -->
            <div class="card card-warning card-outline" id="profileBox">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img v-if="user.image" class="profile-user-img img-fluid img-circle" :src="url+user.image" :alt="user.name">
                  <img v-else class="profile-user-img img-fluid img-circle bg-white" :src="url+'/user.png'" :alt="user.name">
                </div>
                <h3 class="profile-username text-white text-center">{{user.name}}</h3>
                <p class="text-muted text-white text-center">@{{user.username}}</p>
              </div>
            </div>

              <div class="form-group">
                <a>Email <strong>:</strong> </a>
                <span class="float-right"
                      v-if="user.email"><strong>{{ user.email }}</strong></span>
              </div>
              <div class="form-group">
                <a>Country <strong>:</strong> </a>
                <span class="float-right" v-if="user.country && user.country.name"><strong>{{ user.country.name }}</strong></span>
              </div>
              <div class="form-group">
                <a>Date <strong>:</strong> </a>
                <span class="float-right"
                      v-if="user.created_at"><strong>{{ user.created_at | timeDate }}</strong></span>
              </div>
              <div class="form-group">
                <a>Bio <strong>:</strong> </a>
                <p class="font-italic ml-2 mt-2" v-if="user.bio">{{user.bio}}</p>
                <p class="font-italic ml-2 mt-2" v-else>User didn't updated bio</p>
              </div>

          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg-gradient-teal">
            <div class="card-title text-white">User Info</div>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <a href="#editUser" :class="classes.infoBlock">
              <a>Balance <strong>:</strong> </a>
              <span class="float-right"><strong>{{currency}} {{ user.balance }}</strong></span>
            </a>
            <router-link :to="{name: 'user-deposits', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Total Deposits <strong>:</strong> </a>
              <span class="float-right"><strong>{{currency}} {{ infos.deposits }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-withdrawals', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Total Withdrawals <strong>:</strong> </a>
              <span class="float-right"><strong>{{currency}} {{ infos.withdrawals }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-jobs', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Jobs <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.tasks }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-tasks', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Tasks <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.proofs }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-bookings', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Bookings <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.bookings }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-referrals', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Referrals <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.referrals }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-complaints', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Complaints <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.complaints }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>
            <router-link :to="{name: 'user-reports', params:{username:user.username,name:user.name}}" v-if="user.username" :class="classes.infoBlock">
              <a>Reports <strong>:</strong> </a>
              <span class="float-right"><strong>{{ infos.reports }} <i class="fas fa-chevron-right text-white ml-1"></i></strong></span>
            </router-link>

          </div>
        </div>
      </div>

    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="card card-pink">
          <div class="card-header">
            <h3 class="card-title">User Messages</h3>
            <div class="card-tools">
              <router-link :to="{name: 'user-messages', params:{username:user.username,name:user.name}}" v-if="user.username" class="btn btn-tool btn-dark"><i class="fas fa-envelope">All Messages</i></router-link>
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent">
            <loading :active.sync="isMsgLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Chat -->
            <div class="direct-chat-messages">

              <div v-if="parseInt(messages.count) !== 0" class="direct-chat-msg right" v-for="message in messages.items" :key="message.id">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>{{ message.created_at | timeDate }}</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                  {{message.message}}
                </div>
              </div>
              <div v-if="parseInt(messages.count) === 0" class="direct-chat-msg right">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-timestamp ml-1"><small>N/A</small></span>
                </div>
                <div class="direct-chat-text mr-1">
                  No Previous Messages Available !!
                </div>
              </div>


            </div>
            <!--/.direct-chat-messages-->

          </div>
          <div class="card-footer">
            <div class="input-group">
              <textarea type="text" name="message" placeholder="Type Message ..." class="form-control" v-model="messages.message_text" />
              <span class="input-group-append ml-1">
                      <button class="btn btn-warning rounded" @click.prevent="sendMessage">Send</button>
                    </span>
            </div>
          </div>
        </div>
        </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-navy">
          <div class="card-header">
            <h3 class="card-title">Edit User</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body vld-parent" id="editUser">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <!-- Form Row-->
              <div class="row justify-content-start">
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}</small>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Full Name" v-model="form.name">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.email">{{ errors.email[0] }}</small>
              <div class="input-group mb-3">
                <input type="email" class="form-control" placeholder="Email address" v-model="form.email">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}</small>
              <div class="input-group mb-3">
                <input type="password" class="form-control" placeholder="Password" v-model="form.password">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-md-6">
              <small class="text-danger" v-if="errors.country_code">{{ errors.country_code[0] }}</small>
              <div class="input-group mb-3">
                <select class="custom-select" v-model="form.country_code">
                  <option value="" selected>Select Country</option>
                  <option :value="country.code" v-for="country in countries">{{ country.name }}</option>
                </select>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-globe"></span>
                  </div>
                </div>
              </div>
              </div>
                <div class="col-md-6">
                  <small class="text-danger" v-if="errors.balance">{{ errors.balance[0] }}</small>
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" placeholder="Balance" v-model.number="form.balance">
                    <div class="input-group-append">
                      <div class="input-group-text">
                        {{currency}}
                      </div>
                    </div>
                  </div>
                </div>
            <!-- Form Row End -->
                 </div>
            <!-- Form Row End -->

            <!-- Switch Row -->
            <div class="row mt-3 mb-2">
              <div class="col-md-6">
                <div class="form-group">
                  <a>Blocked <strong>:</strong> </a>
                  <switches class="float-right rounded-pill" v-model="form.blocked" color="danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <a>Email Verified <strong>:</strong> </a>
                  <switches class="float-right rounded-pill" v-model="form.verified" />
                </div>
              </div>
            <!-- Switch Row End -->
                    </div>
            <!-- Switch Row End -->
            <hr>
            <div class="text-right">
              <button class="col-4 col-md-3 col-lg-2 btn btn-success" @click.prevent="updateUser">Update</button>
            </div>

          </div>
          <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </div>

    </div>

  </section>
  <!-- /.content -->
</template>

<script>
import axios from "axios";
import ExpandableImage from "@/views/shared/ExpandableImage";
import switches from "@/views/shared/switches";
export default {
  components: {ExpandableImage, switches},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
    this.getMessages();
  },
  computed: {
    currency() {return this.$currency;}
  },
  data(){
    return{
      url: this.$fileURL,
      user: {
        id: null,
        name: null,
        username: null,
        email: null,
        bio: null,
        balance: null,
        is_verified: false,
        image: null,
        banned: null,
        created_at: null,
        country:{
          code: '',
          name: null,
        }
      },
      infos:{
        bookings:0,
        referrals:0,
        complaints:0,
        deposits:0,
        proofs:0,
        reports:0,
        tasks:0,
        withdrawals:0,
      },
      messages: {
        message_text: null,
        count:0,
        items:{}
      },
      form:{
        id: null,
        name: null,
        balance: null,
        email: null,
        country_code: "",
        password: null,
        blocked: false,
        verified: false,
      },
      errors:{},
      countries: {},
      classes: {
        infoBlock: 'btn d-block text-left mb-2 bg-gradient-yellow px-3 py-2 rounded shadow-sm',
      },
      isLoading:false,
      isMsgLoading:false,
      fullPage:false,
      zIndex:10,
    }
  },
  methods:{
    getInfo(){
      this.isLoading = true;
      let username = this.$route.params.username
      axios.get('admin/user-info',{params:{username:username}})
          .then(({data}) => {
            this.user = data.user
            this.infos = data.infos
            this.isLoading = false;
            this.getCountries();
            this.setupForm();
          })
          .catch((error) => {
            this.isLoading = false;
        if (error.response.status === 404 || 401) {
          this.$router.replace({name: 'notFound'});
        }
      })
    },
    setupForm(){
      this.form.id = this.user.id;
      if (this.user.banned){
        this.form.blocked = true;
      }
      if (this.user.is_verified){
        this.form.verified = true;
      }
      this.form.name = this.user.name;
      this.form.email = this.user.email;
      this.form.country_code = this.user.country.code;
      this.form.balance = this.user.balance;
      },
    getCountries(){
      axios.get('country-list')
          .then(({data}) => (this.countries = data))
    },
    updateUser(){
      axios.post('admin/user-update',this.form)
          .then(({data})=> {
            this.form.password = null
            this.$toast('success', ''+data.message+'')
            this.getInfo();
          })
    },
    getMessages(){
      this.isMsgLoading = true;
      let username = this.$route.params.username
      axios.get('admin/last-messages',{params:{username:username}})
          .then(({data}) => {this.messages.items = data.messages;this.messages.count = data.total;this.isMsgLoading = false})
    },
    sendMessage(){
      let username = this.$route.params.username
      axios.post('admin/send-message',{username:username,message:this.messages.message_text})
          .then(({data})=> {
            this.messages.message_text = null;
            this.$toast('success', ''+data.message+'')
            this.getMessages();
          })
    },
  }

}
</script>

<style scoped>
.row {
  justify-content: center;
}
#profilePic {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
#profileBox{
  background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}
</style>
