import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from "@/views/pages/dashboard";
import login from "@/views/auth/login";
import store from "../store/index";
import notFound from "@/views/pages/404"
Vue.use(VueRouter)
// App
import settings from "@/views/pages/settings";
import profile from "@/views/pages/profile";
import faqs from "@/views/pages/faqs";
// Users
import allUsers from "@/views/pages/users/all";
import userDetails from "@/views/pages/users/details";
import userDeposits from "@/views/pages/users/deposits";
import userWithdrawals from "@/views/pages/users/withdrawals";
import userOffers from "@/views/pages/users/offers";
import userTasks from "@/views/pages/users/tasks";
import userBookings from "@/views/pages/users/bookings";
import userReferrals from "@/views/pages/users/referrals";
import userComplaints from "@/views/pages/users/complaints";
import userReports from "@/views/pages/users/reports";
import userMessages from "@/views/pages/users/messages";
// Deposits
import allCategories from "@/views/pages/category/all";

// Deposits
import allDeposits from "@/views/pages/deposit/all";
import depositMethods from "@/views/pages/deposit/methods";
// Withdrawal
import allWithdrawals from "@/views/pages/withdrawal/all";
import withdrawalMethods from "@/views/pages/withdrawal/methods";
// Offers
import allOffers from "@/views/pages/offers/all";
import offerCreate from "@/views/pages/offers/create";
import offerInfo from "@/views/pages/offers/view";
// Complaints
import allComplaints from "@/views/pages/complaint/all";
import complaintDetails from "@/views/pages/complaint/details";

// Packages
import packages from "@/views/pages/packages.vue";
import managePages from "@/views/pages/manage-pages.vue";

const routes = [
  { path: '/', component: dashboard, name: 'dashboard', meta: {reqAuth: true,title: 'Dashboard'} },
  { path: '/login', component: login, name: 'login', meta: {noAuth: true,title: 'Login'} },

    // App
  { path: '/settings', component: settings, name: 'settings', meta: {reqAuth: true,title: 'Settings'} },
  { path: '/profile', component: profile, name: 'profile', meta: {reqAuth: true,title: 'Profile'} },
  { path: '/faqs', component: faqs, name: 'faqs', meta: {reqAuth: true,title: 'F.A.Q'} },
  { path: '/packages', component: packages, name: 'packages', meta: {reqAuth: true,title: 'Packages'} },
  { path: '/manage-pages', component: managePages, name: 'manage-pages', meta: {reqAuth: true,title: 'Manage Pages'} },

  // User
  { path: '/users', component: allUsers, name: 'users', meta: {reqAuth: true,title: 'Users'} },
  { path: '/user/:username', component: userDetails, name: 'user-details', meta: {reqAuth: true,title: 'User Details'}},
  { path: '/user/:username/deposits', component: userDeposits, name: 'user-deposits', meta: {reqAuth: true,title: 'User Deposits'}},
  { path: '/user/:username/withdrawals', component: userWithdrawals, name: 'user-withdrawals', meta: {reqAuth: true,title: 'User Withdrawals'}},
  { path: '/user/:username/jobs', component: userOffers, name: 'user-jobs', meta: {reqAuth: true,title: 'User Jobs'}},
  { path: '/user/:username/tasks', component: userTasks, name: 'user-tasks', meta: {reqAuth: true,title: 'User Tasks'}},
  { path: '/user/:username/bookings', component: userBookings, name: 'user-bookings', meta: {reqAuth: true,title: 'User Bookings'}},
  { path: '/user/:username/referrals', component: userReferrals, name: 'user-referrals', meta: {reqAuth: true,title: 'User Referrals'}},
  { path: '/user/:username/complaints', component: userComplaints, name: 'user-complaints', meta: {reqAuth: true,title: 'User Complaints'}},
  { path: '/user/:username/reports', component: userReports, name: 'user-reports', meta: {reqAuth: true,title: 'User Reports'}},
  { path: '/user/:username/messages', component: userMessages, name: 'user-messages', meta: {reqAuth: true,title: 'User Messages'}},

    // Category
  { path: '/categories', component: allCategories, name: 'all-categories', meta: {reqAuth: true,title: 'Categories'} },

  // Deposit
  { path: '/deposits', component: allDeposits, name: 'all-deposits', meta: {reqAuth: true,title: 'Deposits'} },
  { path: '/deposit-methods', component: depositMethods, name: 'deposit-methods', meta: {reqAuth: true,title: 'Deposit Methods'} },

  // Withdrawal
  { path: '/withdrawals', component: allWithdrawals, name: 'all-withdrawals', meta: {reqAuth: true,title: 'Withdrawals'} },
  { path: '/withdrawal-methods', component: withdrawalMethods, name: 'withdrawal-methods', meta: {reqAuth: true,title: 'Withdrawal Methods'} },

  // Offers
  { path: '/jobs', component: allOffers, name: 'all-jobs', meta: {reqAuth: true,title: 'Jobs'} },
  { path: '/create-job', component: offerCreate, name: 'job-create', meta: {reqAuth: true,title: 'Create Job'} },
  { path: '/job-details/:code', component: offerInfo, name: 'job-details', meta: {reqAuth: true, title: 'Job Details'} },

  // Complaints
  { path: '/complaints', component: allComplaints, name: 'all-complaints', meta: {reqAuth: true,title: 'Complaints'}},
  { path: '/complaint-details-:id', component: complaintDetails, name: 'complaint-details', meta: {reqAuth: true,title: 'Complaint Details'}},

  // 404
  { path: '/404', component: notFound, name: 'notFound',meta:{title: 'Page Not Found'} },
  { path: '*', beforeEnter:(to, from, next)=>{
      next({ path: '/404', replace: true })
    }},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };}
});
router.beforeEach(function (to, from, next){
  if (to.meta.reqAuth && !store.getters.isLoggedIn){
    next('/login')
  } else if (to.meta.noAuth && store.getters.isLoggedIn){
    next('/')
  } else {
    next();
  }
});

export default router
