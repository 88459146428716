<template>
  <div>
    <!-- Main content -->
    <section class="content" style="margin-top: 10px">

      <!-- Default box -->
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-white" style="padding-top: 15px">Deposit Methods</h3>
          <div class="card-tools">
            <button class="btn btn-secondary btn-sm" @click="getDepositMethods"><i
                class="fas fa-sync-alt"></i> Refresh
            </button>
          </div>
        </div>
        <div class="card-header">
          <div class="card-tools">
            <button class="btn btn-success" @click="modalCreate" data-toggle="modal" data-target="#addInfo"><i
                class="fas fa-user-plus"></i> Add New
            </button>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="fullPage"
                   :z-index="zIndex"
                   loader="bars">
          </loading>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="bg-teal">
              <tr>
                <th style="width: 20px">
                  Name
                </th>
                <th style="width: 10px">
                  Logo
                </th>
                <th style="width: 15px">
                  Slug
                </th>
                <th style="width: 15px">
                  Min Amount
                </th>
                <th style="width: 10px" class="text-center">
                  Active
                </th>
                <th style="width: 10px" class="text-center">
                  Action
                </th>
              </tr>
              </thead>
              <tbody v-if="parseInt(methods.total) === 0">
              <td colspan="6">
                <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
              </td>
              </tbody>
              <tbody v-else>
              <tr v-for="method in methods.data" :key="method.id">
                <td>
                  {{ method.name }}
                </td>
                <td>
                  <img v-if="method.logo" :src="url+method.logo" width="50px" height="50px" :alt="method.name">
                  <img v-else src="na.png" width="50px" height="50px" :alt="method.name">
                </td>
                <td>
                  {{ method.slug }}
                </td>
                <td>
                  {{ currency }} {{ method.min_amount }}
                </td>
                <td class="project-state">
                  <span class="badge badge-danger" v-if="parseInt(method.active) === 0">No</span>
                  <span class="badge badge-success" v-if="parseInt(method.active) === 1">Yes</span>
                </td>
                <td class="project-actions text-right">
                  <button class="btn btn-danger" @click="setInfo(method)" data-toggle="modal" data-target="#viewInfo">
                    View Details
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer clearfix">
          <pagination class="pagination pagination-sm m-0 float-right" :data="methods"
                      @pagination-change-page="getDepositMethods"></pagination>
        </div>
      </div>
      <!-- /.card -->
      <!-- View Modal -->
      <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">Method Info</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-info">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <a>Name <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.name"><strong>{{ info.name }}</strong></span>
              </div>
              <div class="form-group">
                <a>Slug <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.slug"><strong>{{ info.slug }}</strong></span>
              </div>
              <div class="form-group mb-5">
                <a>Logo <strong>:</strong> </a>
                <img class="float-right" v-if="info.logo" :src="url+info.logo" width="50px" height="50px"
                     :alt="info.name">
                <img class="float-right" v-else src="na.png" width="50px" height="50px" :alt="info.name">
              </div>
              <div class="form-group">
                <a>Minimum Amount <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.min_amount"><strong>{{ currency }} {{ info.min_amount }}</strong></span>
              </div>
              <div class="form-group">
                <a>Active <strong>:</strong> </a>
                <span class="badge badge-danger float-right" v-if="parseInt(info.active) === 0">No</span>
                <span class="badge badge-success float-right" v-if="parseInt(info.active) === 1">Yes</span>
              </div>
              <div class="form-group">
                <a>Instructions <strong>:</strong> </a>
                <div id="instructions" class="card-body card2" v-if="info.instructions" v-html="info.instructions">
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="modalUpdate" data-toggle="modal"
                      data-target="#addInfo">Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit/Add Modal -->
      <div class="modal fade" id="addInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="addNew" class="modal-title" id="createModalLabel">Create Method</h5>
              <h5 v-if="!addNew" class="modal-title" id="updateModalLabel">Update Method</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <form class="form-horizontal" enctype="multipart/form-data">
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Logo</label>
                  <div class="col-sm-10">
                    <div class="card card-primary justify-content-center">
                      <div class="card-body box-profile">
                        <div class="text-center">
                          <input type="file" ref="depositLogo" accept=".jpg,.png,.jpeg,.heic" class="custom-file"
                                 @change="imageChange">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row" v-if="info.logo">
                  <label class="col-sm-2 col-form-label">Preview</label>
                  <div class="col-sm-10">
                    <div class="card card-primary justify-content-center">
                      <div class="card-body box-profile">
                        <div class="text-center">
                          <img class="profile-user-img img-fluid img-circle"
                               :src="info.logo"
                               alt="User profile picture">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputName"
                           placeholder="Method Name" v-model="info.name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputSlug"
                         class="col-sm-2 col-form-label">Slug</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputSlug"
                           placeholder="Method Slug" v-model="info.slug">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputAmount"
                         class="col-sm-2 col-form-label">Minimum Amount</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="inputAmount"
                           placeholder="Minimum Amount" v-model.number="info.min_amount">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputActive" class="col-sm-2 col-form-label">Active</label>
                  <div class="col-sm-10">
                    <select id="inputActive" class="custom-select" v-model.number="info.active">
                      <option value="1" selected>Yes</option>
                      <option value="0" selected>No</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Instructions :</label>
                  <div>
                    <vue-editor id="inputDescription" v-model="info.instructions" :editorToolbar="customToolbar"/>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button v-if="addNew" type="button" class="btn btn-success" @click.prevent="methodCreate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Add Method
                </button>
                <button v-if="!addNew" type="button" class="btn btn-warning" @click.prevent="methodUpdate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import axios from "axios";
import svgLoading from "@/views/shared/loading-svg";
import {VueEditor} from "vue2-editor";

export default {
  components: {VueEditor, svgLoading},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getDepositMethods();
  },
  computed: {
    currency() {
      return this.$currency;
    }
  },
  data() {
    return {
      url: this.$fileURL,
      methods: {
        total: 0,
      },
      info: {
        id: null,
        name: '',
        logo: null,
        min_amount: 0,
        active: null,
        instructions: '',
      },
      errors: {},
      addNew: false,
      loading: false,
      customToolbar: [
        [{
          header: [false, 1, 2, 3]
        }], ["bold", "italic", "underline", "clean"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}], ["blockquote", "video"],
        [{list: "ordered"}, {list: "bullet"}], [{indent: "-1"}, {indent: "+1"}], [{color: []}, {background: []}]
      ],
      isLoading: false,
      fullPage: false,
      zIndex: 10,
    }
  },
  methods: {
    getDepositMethods(page = 1) {
      this.isLoading = true;
      axios.get('admin/deposit-methods?page=' + page)
          .then(({data}) => {
            this.methods = data;
            this.isLoading = false
          })
    },
    setInfo(deposit) {
      this.info = deposit
    },
    imageChange(event) {
      this.info.logo = null;
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.info.logo = event.target.result
      };
      reader.readAsDataURL(file);
    },
    methodCreate() {
      this.loading = true;
      axios.post('admin/deposit-method-add', this.info)
          .then(() => {
            this.info.logo = null;
            this.getDepositMethods();
            this.loading = false;
            this.$toast('success', 'Method Created Successfully');
            document.getElementById('close').click();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    methodUpdate() {
      this.loading = true;
      axios.patch('admin/deposit-method-update', this.info)
          .then(() => {
            this.info.logo = null;
            this.loading = false;
            this.getDepositMethods();
            this.$toast('success', 'Method Updated');
            this.toggleModal();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    modalUpdate() {
      this.addNew = false;
      this.info.logo = null;
      this.$refs.depositLogo.value = null;
    },
    modalCreate() {
      this.addNew = true;
      this.info = {id: null, active: 1, name: '', logo: null, min_amount: null, instructions: ''};
      this.$refs.depositLogo.value = null;
    },
    toggleModal() {
      document.getElementById('close').click();
      document.getElementById('close-info').click();
    },

  },

}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

#instructions {
  color: floralwhite;
  background-image: linear-gradient(109.6deg, rgba(121, 203, 202, 1) 11.2%, rgba(119, 161, 211, 1) 91.1%);
}
</style>
