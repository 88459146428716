import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import moment from "moment"

import 'vue-loading-overlay/dist/vue-loading.css';

Vue.component('Loading', require('vue-loading-overlay'));
require('./store/token');

axios.defaults.baseURL = document.getElementById('baseURL').value;
Vue.prototype.$fileURL = document.getElementById('fileURL').value;
store.dispatch('setAuth')
store.dispatch('attempt', localStorage.getItem('token'))

let sitename = document.getElementById('siteName').value;
let logotext = document.getElementById('logoText').value;
let currency = document.getElementById('siteCurrency').value;

axios.get('site-info').then(({data}) => {
    if (data.name) {
        localStorage.setItem('sitename', data.name)
    } else {
        localStorage.setItem('sitename', sitename)
    }
    if (data.logotext) {
        localStorage.setItem('logotext', data.logotext)
    } else {
        localStorage.setItem('logotext', logotext)
    }
    if (data.currency) {
        localStorage.setItem('currency', data.currency)
    } else {
        localStorage.setItem('currency', currency)
    }
})

if (localStorage.getItem('sitename')) {
    Vue.prototype.$sitename = localStorage.getItem('sitename');
} else {
    Vue.prototype.$sitename = sitename;
}
if (localStorage.getItem('logotext')) {
    Vue.prototype.$logotext = localStorage.getItem('logotext');
} else {
    Vue.prototype.$logotext = logotext;
}
if (localStorage.getItem('currency')) {
    Vue.prototype.$currency = localStorage.getItem('currency');
} else {
    Vue.prototype.$currency = currency;
}
document.getElementById('baseURL').remove();
document.getElementById('fileURL').remove();
document.getElementById('siteName').remove();
document.getElementById('logoText').remove();
document.getElementById('siteCurrency').remove();

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
const Toast = function (type, title) {

    this.$swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        icon: type,
        title: title
    })
}
Vue.prototype.$toast = Toast;
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.filter('fromNow', function (date) {
    return moment.utc(date).fromNow();
})

Vue.filter('dateFormat', function (date) {
    return moment.utc(date).format('Do MMMM, YYYY');
})
Vue.filter('timeDate', function (date) {
    return moment.utc(date).format('hh:mm A, Do MMMM, YYYY');
})

Vue.config.productionTip = false
Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true;

Vue.filter('formatNumber', function (value) {
    let b = value;
    if (b < 1000) {
        return b;
    }
    if (b >= 1000 && b < 1000000) {
        let fb = (b / 1000).toFixed(2);
        return fb + 'k';
    }
    if (b >= 1000000) {
        let fb = (b / 1000000).toFixed(2);
        return fb + 'm';
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
