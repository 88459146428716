<template>
    <div>

        <section class="content" style="margin-top: 10px">
            <!-- Default box -->
            <div class="card">
                <div class="card-header" style="padding-top: 15px">
                    <h3 class="card-title text-white">{{this.$route.params.name}}'s Tasks</h3>
                    <div class="card-tools">
                        <button class="btn btn-secondary btn-sm" @click="getTasks"><i class="fas fa-sync-alt"></i>
                            Refresh
                        </button>
                    </div>
                </div>
                <div class="card-body bg-gray-light vld-parent">
                  <loading :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-page="fullPage"
                           :z-index="zIndex"
                           loader="bars">
                  </loading>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-teal">
                            <tr>
                                <th class="text-center">
                                    Task
                                </th>
                                <th class="text-center">
                                    Price
                                </th>
                                <th class="text-center">
                                    Status
                                </th>
                                <th class="text-center">
                                    Action
                                </th>
                            </tr>
                            </thead>
                          <tbody v-if="parseInt(tasks.total) === 0">
                          <td colspan="4">
                            <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                          </td>
                          </tbody>
                            <tbody v-else>
                            <tr v-if="parseInt(tasks.total) > 0" v-for="task in tasks.data" :key="task.task.code">
                                <td class="text-center">
                                    <router-link :to="{name: 'offer-details', params:{code:task.task.code}}">
                                        {{ task.task.title }}
                                    </router-link>
                                </td>
                                <td class="text-center">
                                  {{currency}} {{ task.task.price }}
                                </td>
                                <td class="text-center">
                                    <a class="badge badge-warning" v-if="parseInt(task.status) === 0">Pending</a>
                                    <a class="badge badge-success" v-if="parseInt(task.status) === 1">Completed</a>
                                    <a class="badge badge-danger" v-if="parseInt(task.status) === 2">Rejected</a>
                                    <a class="badge badge-primary" v-if="parseInt(task.status) === 3">In Review</a>
                                </td>
                                <td class="text-center">
                                    <button class="btn btn-danger" data-toggle="modal" data-target="#proofInfo"
                                            @click="setProof(task)">View
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-if="parseInt(tasks.total) < 1" class="d-block justify-content-center text-center">
                            <label class="text-center"> Didn't find any Task to show </label> <br>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix">
                    <pagination class="pagination pagination-sm m-0 float-right" :data="tasks"
                                @pagination-change-page="getTasks"></pagination>
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="proofInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Proof Info</h5>
                            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
                              <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group" v-if="selected.task">
                                <label>Task :</label>
                                <router-link :to="{name: 'offer-details', params:{code:selected.task.code}}"
                                             class="float-right">
                                    {{ selected.task.title }}
                                </router-link>
                            </div>
                            <div class="form-group">
                                <label>Status :</label>
                                <a class="badge badge-warning float-right" v-if="parseInt(selected.status) === 0">Pending</a>
                                <a class="badge badge-success float-right" v-if="parseInt(selected.status) === 1">Completed</a>
                                <a class="badge badge-danger float-right" v-if="parseInt(selected.status) === 2">Rejected</a>
                                <a class="badge badge-primary float-right" v-if="parseInt(selected.status) === 3">In Review</a>
                            </div>
                            <div class="form-group">
                                <label>Submitted :</label>
                                <p class="float-right">{{ selected.created_at | fromNow }}</p>
                            </div>
                            <div class="form-group">
                                <label>Note</label>
                                <p style="white-space: pre">{{ selected.comment }}</p>
                            </div>
                            <div class="form-group">
                                <label>Screenshots :</label>
                                <div id="proofImage" v-if="selected.images.length > 1">
                                    <expandable-image v-for="(image,index) in selected.images" :key="index" :src="url+image"
                                                      :close-on-background-click="true" alt="" style="padding: 5px"/>
                                </div>
                                <div v-else class="w-50 px-2 py-2 flex flex-wrap justify-center">
                                    <expandable-image v-for="(image,index) in selected.images" :key="index" :src="url+image"
                                                      :close-on-background-click="true" alt="" style="padding: 5px"/>
                                </div>
                            </div>
                            <div class="form-group" v-if="parseInt(selected.status) === 2 && selected.reject_note">
                                <label>Rejected Note :</label>
                                <p style="color: #ff0000;white-space: pre">{{ selected.reject_note }}</p>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script>
import axios from "axios";
import ExpandableImage from "@/views/shared/ExpandableImage";
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
    created() {
        this.getTasks();
    },
  computed: {
    currency() {return this.$currency;}
  },
    data() {
        return {
          url:this.$fileURL,
            tasks: {
                total: 0,
            },
            selected: {
                reject_note: null,
                images: [],
            },
            report: {
                status: 3,
                reply: null
            },
          isLoading:false,
          fullPage:false,
          zIndex:10,
        }
    },
    components: {ExpandableImage},
    methods: {
        getTasks(page = 1) {
          this.isLoading = true
          let username = this.$route.params.username;
            axios.get('admin/user-tasks',{params:{username:username,page:page}})
                .then(({data}) => {this.tasks = data;this.isLoading = false})
        },
        setProof(proof) {
            this.selected = proof
            this.selected.images = JSON.parse(this.selected.images)
        },
    },
}
</script>

<style scoped>
#proofImage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 100%;
    position: relative;
}

.card {
    background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
