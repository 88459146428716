<template>
  <section class="content" style="margin-top: 10px">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header" style="padding-top: 15px">
            <h3 class="card-title text-white">Withdrawals</h3>
            <div class="card-tools">
              <button class="btn btn-secondary btn-sm" @click="getWithdrawals"><i
                  class="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
          <div class="card-header" style="padding-top: 10px">
            <div class="row" style="justify-content: center;">
              <div style="width: 35%; margin-right: 5px; margin-bottom: 10px;">
                <select id="status" class="custom-select" v-model="filter.status" @change="getWithdrawals">
                  <option value="3" selected>All</option>
                  <option value="0">Pending</option>
                  <option value="1">Successful</option>
                  <option value="2">Rejected</option>
                </select>
              </div>
              <div style="width: 55%; margin-bottom: 10px;">
                <select id="sort-by" class="custom-select" v-model="filter.sortBy" @change="getWithdrawals">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage"
                     :z-index="zIndex"
                     loader="bars">
            </loading>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead class="bg-teal">
                <tr>
                  <th>
                    User
                  </th>
                  <th>
                    Method
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Submitted
                  </th>
                  <th>
                    Status
                  </th>
                  <th style="text-align: center">
                    Action
                  </th>
                </tr>
                </thead>
                <tbody v-if="parseInt(withdrawals.total) === 0">
                <td colspan="6">
                  <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
                </td>
                </tbody>
                <tbody v-else>
                <tr v-for="withdrawal in withdrawals.data">
                  <td>
                    <router-link :to="{name: 'user-details', params:{username:withdrawal.user.username}}">
                      {{ withdrawal.user.name }}
                    </router-link>
                  </td>
                  <td>
                    {{ withdrawal.method.name }}
                  </td>
                  <td>
                    {{ currency }} {{ withdrawal.amount }}
                  </td>
                  <td>
                    {{ withdrawal.date | dateFormat }}
                  </td>
                  <td class="text-center">
                    <a class="btn btn-warning btn-sm" v-if="parseInt(withdrawal.status) === 0">Pending</a>
                    <a class="btn btn-success btn-sm" v-if="parseInt(withdrawal.status) === 1">Successful</a>
                    <a class="btn btn-danger btn-sm" v-if="parseInt(withdrawal.status) === 2">Rejected</a>
                  </td>
                  <td class="project-actions text-center">
                    <button class="btn btn-danger" data-toggle="modal" data-target="#viewInfo"
                            @click="setInfo(withdrawal)">View Details
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-center">
            <pagination class="pagination pagination-sm m-0 float-right" :data="withdrawals"
                        @pagination-change-page="getWithdrawals"></pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Withdrawal Info</h5>
            <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <a>User <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.user.name"><strong>{{ info.user.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Deposit Method <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.method.name"><strong>{{ info.method.name }}</strong></span>
            </div>
            <div class="form-group">
              <a>Amount <strong>:</strong> </a>
              <span class="float-right" v-if="info.amount"><strong>{{ currency }} {{ info.amount }}</strong></span>
            </div>
            <div class="form-group">
              <a>Status <strong>:</strong> </a>
              <a class="badge badge-warning float-right" v-if="parseInt(info.status) === 0">Pending</a>
              <a class="badge badge-success float-right" v-if="parseInt(info.status) === 1">Successful</a>
              <a class="badge badge-danger float-right" v-if="parseInt(info.status) === 2">Rejected</a>
            </div>
            <div class="form-group">
              <a>Date <strong>:</strong> </a>
              <span class="float-right"
                    v-if="info.date"><strong>{{ info.date | dateFormat }}</strong></span>
            </div>
            <div class="form-group">
              <label>Details</label>
              <p type="text" style="white-space: pre">{{ info.details }}</p>
            </div>
          </div>
          <div v-if="parseInt(info.status) === 0" class="modal-footer">
            <button class="btn btn-danger" @click="rejectWithdrawal(info.id)">Reject</button>
            <button class="btn btn-success" @click="approveWithdrawal(info.id)">Approve</button>
          </div>
          <div class="modal-footer" v-else>
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getWithdrawals();
  },
  computed: {
    currency() {
      return this.$currency;
    }
  },
  data() {
    return {
      withdrawals: {
        total: 0,
      },
      info: {
        amount: null,
        details: null,
        date: null,
        status: null,
        method: {
          name: null,
        },
        user: {
          name: null,
        },
      },
      filter: {
        status: '3',
        sortBy: 'desc',
      },
      isLoading: false,
      fullPage: false,
      zIndex: 10,
    }
  },
  methods: {
    getWithdrawals(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== '') {
        status = parseInt(status)
        if (status === 3) {
          status = ''
        }
      }
      let sortBy = this.filter.sortBy
      axios.get('admin/withdrawals', {params: {status: status, sortBy: sortBy, page: page}})
          .then(({data}) => {
            this.withdrawals = data;
            this.isLoading = false
          })
    },
    setInfo(info) {
      this.info = info
    },
    approveWithdrawal(id) {
      axios.post('admin/approve-withdrawal/' + id)
          .then(() => {
            this.getWithdrawals();
            this.$toast('success', 'Approved Successfully')
          })
      this.toggleModal();
    },
    rejectWithdrawal(id) {
      axios.post('admin/reject-withdrawal/' + id)
          .then(() => {
            this.getWithdrawals();
            this.$toast('warning', 'Rejected Successfully')
          })
      this.toggleModal();
    },
    toggleModal() {
      document.getElementById('close').click();
    },

  },
}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
</style>
