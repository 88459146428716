<template>
  <div>
    <!-- Main content -->
    <section class="content" style="margin-top: 10px">

      <!-- Default box -->
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-white" style="padding-top: 15px">Packages</h3>
          <div class="card-tools">
            <button class="btn btn-secondary btn-sm" @click="getPackages"><i
                class="fas fa-sync-alt"></i> Refresh
            </button>
          </div>
        </div>
        <div class="card-header">
          <div class="card-tools">
            <button class="btn btn-success" @click="modalCreate" data-toggle="modal" data-target="#addInfo"><i
                class="fas fa-user-plus"></i> Add New
            </button>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center vld-parent bg-gray-light text-center">
          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="fullPage"
                   :z-index="zIndex"
                   loader="bars">
          </loading>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="bg-teal">
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Price
                </th>
                <th>
                  Task Limit
                </th>
                <th class="text-center">
                  Action
                </th>
              </tr>
              </thead>
              <tbody v-if="parseInt(packages.total) === 0">
              <td colspan="4">
                <div class="card-body bg-gray-light text-center text-xl">No Data To Show !!</div>
              </td>
              </tbody>
              <tbody v-else>
              <tr v-for="item in packages.data" :key="item.id">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ currency }} {{ item.min_amount }}
                </td>
                <td>
                  {{ item.daily_task_limit }}
                </td>
                <td class="project-actions text-right">
                  <button class="btn btn-danger" @click="setInfo(item)" data-toggle="modal" data-target="#viewInfo">
                    View Details
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer clearfix">
          <pagination class="pagination pagination-sm m-0 float-right" :data="packages"
                      @pagination-change-page="getPackages"></pagination>
        </div>
      </div>
      <!-- /.card -->
      <!-- View Modal -->
      <div class="modal fade" id="viewInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">Package Info</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close-info">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <a>Name <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.name"><strong>{{ info.name }}</strong></span>
              </div>
              <div class="form-group">
                <a>Price <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.price"><strong>{{ currency }} {{ info.price }}</strong></span>
              </div>

              <div class="form-group">
                <a>Daily Task Limit <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.daily_task_limit"><strong>{{ info.daily_task_limit }}</strong></span>
              </div>

              <div class="form-group">
                <a>Package Level 1 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.ref_l1_commission"><strong>{{ info.ref_l1_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Package Level 2 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.ref_l2_commission"><strong>{{ info.ref_l2_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Package Level 3 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.ref_l3_commission"><strong>{{ info.ref_l3_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Package Level 4 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.ref_l4_commission"><strong>{{ info.ref_l4_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Package Level 5 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.ref_l5_commission"><strong>{{ info.ref_l5_commission }}</strong></span>
              </div>

              <div class="form-group">
                <a>Withdrawal Level 1 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.withdrawal_l1_commission"><strong>{{ info.withdrawal_l1_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Withdrawal Level 2 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.withdrawal_l2_commission"><strong>{{ info.withdrawal_l2_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Withdrawal Level 3 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.withdrawal_l3_commission"><strong>{{ info.withdrawal_l3_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Withdrawal Level 4 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.withdrawal_l4_commission"><strong>{{ info.withdrawal_l4_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Withdrawal Level 5 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.withdrawal_l5_commission"><strong>{{ info.withdrawal_l5_commission }}</strong></span>
              </div>

              <div class="form-group">
                <a>Tasks Level 1 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.task_l1_commission"><strong>{{ info.task_l1_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Tasks Level 2 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.task_l2_commission"><strong>{{ info.task_l2_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Tasks Level 3 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.task_l3_commission"><strong>{{ info.task_l3_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Tasks Level 4 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.task_l4_commission"><strong>{{ info.task_l4_commission }}</strong></span>
              </div>
              <div class="form-group">
                <a>Tasks Level 5 Commission: <strong>:</strong> </a>
                <span class="float-right"
                      v-if="info.task_l5_commission"><strong>{{ info.task_l5_commission }}</strong></span>
              </div>

              <div class="form-group">
                <a>Description <strong>:</strong> </a>
                <p class="mt-2" style="white-space: pre" v-if="info.description">
                  {{ info.description }}
                </p>
              </div>

            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="modalUpdate" data-toggle="modal"
                      data-target="#addInfo">Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit/Add Modal -->
      <div class="modal fade" id="addInfo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="addNew" class="modal-title" id="createModalLabel">Create Package</h5>
              <h5 v-if="!addNew" class="modal-title" id="updateModalLabel">Update Package</h5>
              <button type="button" class="btn btn-dark" data-dismiss="modal" aria-label="Close" id="close">
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <form class="form-horizontal" enctype="multipart/form-data">
              <div class="modal-body">
                <div class="form-group row">
                  <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputName"
                           placeholder="Package Name" v-model="info.name">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputSlug"
                         class="col-sm-2 col-form-label">Price</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="inputSlug"
                           placeholder="Package Price" v-model="info.price">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="inputAmount"
                         class="col-sm-2 col-form-label">Daily Task Limit</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="inputAmount"
                           placeholder="Daily Task Limit" v-model.number="info.daily_task_limit">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input_ref_l1_commission"
                         class="col-sm-2 col-form-label">Package Level 1 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_ref_l1_commission"
                           placeholder="Package Commission Percentage for Level 1 Referrer"
                           v-model.number="info.ref_l1_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_ref_l2_commission"
                         class="col-sm-2 col-form-label">Package Level 2 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_ref_l2_commission"
                           placeholder="Package Commission Percentage for Level 2 Referrer"
                           v-model.number="info.ref_l2_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_ref_l3_commission"
                         class="col-sm-2 col-form-label">Package Level 3 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_ref_l3_commission"
                           placeholder="Package Commission Percentage for Level 3 Referrer"
                           v-model.number="info.ref_l3_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_ref_l4_commission"
                         class="col-sm-2 col-form-label">Package Level 4 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_ref_l4_commission"
                           placeholder="Package Commission Percentage for Level 4 Referrer"
                           v-model.number="info.ref_l4_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_ref_l5_commission"
                         class="col-sm-2 col-form-label">Package Level 5 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_ref_l5_commission"
                           placeholder="Package Commission Percentage for Level 5 Referrer"
                           v-model.number="info.ref_l5_commission">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input_withdrawal_l1_commission"
                         class="col-sm-2 col-form-label">Withdrawal Level 1 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_withdrawal_l1_commission"
                           placeholder="Withdrawal Commission Percentage for Level 1 Referrer"
                           v-model.number="info.withdrawal_l1_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_withdrawal_l2_commission"
                         class="col-sm-2 col-form-label">Withdrawal Level 2 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_withdrawal_l2_commission"
                           placeholder="Withdrawal Commission Percentage for Level 2 Referrer"
                           v-model.number="info.withdrawal_l2_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_withdrawal_l3_commission"
                         class="col-sm-2 col-form-label">Withdrawal Level 3 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_withdrawal_l3_commission"
                           placeholder="Withdrawal Commission Percentage for Level 3 Referrer"
                           v-model.number="info.withdrawal_l3_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_withdrawal_l4_commission"
                         class="col-sm-2 col-form-label">Withdrawal Level 4 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_withdrawal_l4_commission"
                           placeholder="Withdrawal Commission Percentage for Level 4 Referrer"
                           v-model.number="info.withdrawal_l4_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_withdrawal_l5_commission"
                         class="col-sm-2 col-form-label">Withdrawal Level 5 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_withdrawal_l5_commission"
                           placeholder="Withdrawal Commission Percentage for Level 5 Referrer"
                           v-model.number="info.withdrawal_l5_commission">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="input_task_l1_commission"
                         class="col-sm-2 col-form-label">Task Level 1 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_task_l1_commission"
                           placeholder="Task Commission Percentage for Level 1 Referrer"
                           v-model.number="info.task_l1_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_task_l2_commission"
                         class="col-sm-2 col-form-label">Task Level 2 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_task_l2_commission"
                           placeholder="Task Commission Percentage for Level 2 Referrer"
                           v-model.number="info.task_l2_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_task_l3_commission"
                         class="col-sm-2 col-form-label">Task Level 3 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_task_l3_commission"
                           placeholder="Task Commission Percentage for Level 3 Referrer"
                           v-model.number="info.task_l3_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_task_l4_commission"
                         class="col-sm-2 col-form-label">Task Level 4 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_task_l4_commission"
                           placeholder="Task Commission Percentage for Level 4 Referrer"
                           v-model.number="info.task_l4_commission">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="input_task_l5_commission"
                         class="col-sm-2 col-form-label">Task Level 5 Commission</label>
                  <div class="col-sm-10">
                    <input type="number" class="form-control" id="input_task_l5_commission"
                           placeholder="Task Commission Percentage for Level 5 Referrer"
                           v-model.number="info.task_l5_commission">
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Description :</label>
                  <div>
                    <textarea class="form-control" id="inputDescription" v-model="info.description"/>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                <button v-if="addNew" type="button" class="btn btn-success" @click.prevent="methodCreate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Create Package
                </button>
                <button v-if="!addNew" type="button" class="btn btn-warning" @click.prevent="methodUpdate">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import axios from "axios";
import svgLoading from "@/views/shared/loading-svg";
import {VueEditor} from "vue2-editor";

export default {
  components: {VueEditor, svgLoading},
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getPackages();
  },
  computed: {
    currency() {
      return this.$currency;
    }
  },
  data() {
    return {
      packages: {
        total: 0,
      },
      info: {
        id: null,
        name: '',
        price: null,
        description: '',
        daily_task_limit: null,
        ref_l1_commission: null,
        ref_l2_commission: null,
        ref_l3_commission: null,
        ref_l4_commission: null,
        ref_l5_commission: null,
        withdrawal_l1_commission: null,
        withdrawal_l2_commission: null,
        withdrawal_l3_commission: null,
        withdrawal_l4_commission: null,
        withdrawal_l5_commission: null,
        task_l1_commission: null,
        task_l2_commission: null,
        task_l3_commission: null,
        task_l4_commission: null,
        task_l5_commission: null,
      },
      errors: {},
      addNew: false,
      loading: false,
      customToolbar: [
        [{
          header: [false, 1, 2, 3]
        }], ["bold", "italic", "underline", "clean"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}], ["blockquote", "video"],
        [{list: "ordered"}, {list: "bullet"}], [{indent: "-1"}, {indent: "+1"}], [{color: []}, {background: []}]
      ],
      isLoading: false,
      fullPage: false,
      zIndex: 10,
    }
  },
  methods: {
    getPackages(page = 1) {
      this.isLoading = true;
      axios.get('admin/packages?page=' + page)
          .then(({data}) => {
            this.packages = data;
            this.isLoading = false
          })
    },
    setInfo(deposit) {
      this.info = deposit
    },
    methodCreate() {
      this.loading = true;
      axios.post('admin/package-add', this.info)
          .then(() => {
            this.info.logo = null;
            this.getPackages();
            this.loading = false;
            this.$toast('success', 'Package Created Successfully');
            document.getElementById('close').click();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    methodUpdate() {
      this.loading = true;
      axios.patch('admin/package-update', this.info)
          .then(() => {
            this.info.logo = null;
            this.loading = false;
            this.getPackages();
            this.$toast('success', 'Package Updated');
            this.toggleModal();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data.errors
            this.$toast('error', 'Error !!');
          })
    },
    modalUpdate() {
      this.addNew = false;
      this.info.logo = null;
      this.$refs.depositLogo.value = null;
    },
    modalCreate() {
      this.addNew = true;
      this.resetData();
    },
    resetData() {
      this.info = {
        id: null,
        name: '',
        price: null,
        description: '',
        daily_task_limit: null,
        ref_l1_commission: null,
        ref_l2_commission: null,
        ref_l3_commission: null,
        ref_l4_commission: null,
        ref_l5_commission: null,
        withdrawal_l1_commission: null,
        withdrawal_l2_commission: null,
        withdrawal_l3_commission: null,
        withdrawal_l4_commission: null,
        withdrawal_l5_commission: null,
        task_l1_commission: null,
        task_l2_commission: null,
        task_l3_commission: null,
        task_l4_commission: null,
        task_l5_commission: null,
      }
    },
    toggleModal() {
      document.getElementById('close').click();
      document.getElementById('close-info').click();
    },

  },

}
</script>

<style scoped>
.card {
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

#instructions {
  color: floralwhite;
  background-image: linear-gradient(109.6deg, rgba(121, 203, 202, 1) 11.2%, rgba(119, 161, 211, 1) 91.1%);
}
</style>
