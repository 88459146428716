<template>
  <div v-if="isLoggedIn">
    <aside class="main-sidebar sidebar-dark-primary elevation-4" id="sidebar">
      <!-- Brand Logo -->
      <a href="/" class="brand-link">
        <img src="/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">{{ this.$logotext }}</span>
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li class="nav-header">MainMenu</li>

            <li class="nav-item">
              <router-link to="/" class="nav-link">
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/users" class="nav-link">
                <i class="nav-icon fas fa-user-astronaut"></i>
                <p>Users</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/jobs" class="nav-link">
                <i class="nav-icon fas fa-briefcase"></i>
                <p>Jobs</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/create-job" class="nav-link">
                <i class="nav-icon fas fa-folder-plus"></i>
                <p>Create Job</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/packages" class="nav-link">
                <i class="nav-icon fas fa-book-reader"></i>
                <p>Packages</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/complaints" class="nav-link">
                <i class="nav-icon fas fa-flag"></i>
                <p>Complaints</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/categories" class="nav-link">
                <i class="nav-icon fas fa-book-medical"></i>
                <p>Categories</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/deposits" class="nav-link">
                <i class="nav-icon fas fa-piggy-bank"></i>
                <p>Deposits</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/deposit-methods" class="nav-link">
                <i class="nav-icon fas fa-money-check-alt"></i>
                <p>Deposit Methods</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/withdrawals" class="nav-link">
                <i class="nav-icon fas fa-hand-holding-usd"></i>
                <p>Withdrawals</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/withdrawal-methods" class="nav-link">
                <i class="nav-icon fas fa-wallet"></i>
                <p>Withdrawal Methods</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/settings" class="nav-link">
                <i class="nav-icon fas fa-sliders-h"></i>
                <p>Settings</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/faqs" class="nav-link">
                <i class="nav-icon fas fa-question-circle"></i>
                <p>F.A.Q</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/manage-pages" class="nav-link">
                <i class="nav-icon fas fa-book"></i>
                <p>Pages</p>
              </router-link>
            </li>

          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  },
}
</script>

<style scoped>
.main-sidebar .sidebar {
  overflow-y: scroll;
}

#sidebar {
  background-image: radial-gradient(circle farthest-corner at -4% -12.9%, rgba(74, 98, 110, 1) 0.3%, rgba(30, 33, 48, 1) 90.2%);
}
</style>